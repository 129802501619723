
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  onBeforeUnmount,
  shallowRef,
  ref,
} from "vue";
import {
  getColumnOneList,
  getStudyList,
  getStudyInfo,
  saveStudy,
  getModelList,
  getModelInfo,
} from "@/api/index.js";
import { ElMessage } from "element-plus";
import Sidebar from "@/components/Sidebar/index.vue";
import Details from "@/components/Details/index.vue";
import triangleIcon from "assets/images/triangle_icon.png";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";

import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IToolbarConfig } from "@wangeditor/editor";
import { getToken, getUid } from "@/utils/cookies.js";
import type { FormInstance, FormRules } from "element-plus";

export default defineComponent({
  setup() {
    interface props {
      type?: any;
      articleId?: any;
      menuList?: Array<any>;
      list?: Array<any>;
      keyword?: string;
      total?: any;
      page?: any;
      limit?: any;
      isShow?: boolean;
      isAskQuestionsShow?: boolean;
      role?: any;
      currentItem: {
        title?: string;
        user_name?: string;
        time?: string;
        content?: any;
        reply_info?: any;
      };
      form: { title: string; content: any };
      loading?: boolean;
    }
    let state: props = reactive({
      type: "0",
      articleId: "",
      menuList: [],
      list: [],
      keyword: "",
      total: 0,
      page: 1,
      limit: 10,
      isShow: false,
      isAskQuestionsShow: false,
      role: "0", // 0 所有问题 1 自己的问题
      currentItem: {
        title: "",
        user_name: "",
        time: "",
        content: "",
        reply_info: "",
      },
      form: { title: "", content: "" },
    });

    const ruleFormRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      title: [
        {
          required: true,
          message: "请输入标题",
          trigger: "blur",
        },
      ],
      content: [
        {
          required: true,
          message: "请输入详细内容",
          trigger: "blur",
        },
      ],
    });

    // 获取学习天地栏目
    getColumnOneList({ pid: 18 }).then((res: any) => {
      if (res.code == "0") {
        state.menuList = [...res.data];
      } else {
        ElMessage.error(res.msg);
      }
    });

    const activeIndex = computed(() => {
      let index = 0;
      state.menuList?.map((r, i) => {
        if (r.type == state.type) {
          index = i;
        }
      });
      return index;
    });

    const $route = useRoute();
    const $router = useRouter();

    const sidebarclick = (item: any) => {
      state.type = item.type;
      $router.push({ query: ($route.query, { type: item.type }) });
      state.isShow = false;
      state.isAskQuestionsShow = false;
      state.keyword = "";
      state.role = "0";
      state.list = [];
      state.total = 0;
    };

    const modelListFn = async (type: any) => {
      const params = {
        type,
        keyword: state.keyword,
        page: state.page,
        limit: state.limit,
      };
      return await getModelList(params).then((res: any) => {
        if (res.code == "0") {
          return res;
        }
      });
    };

    const studyListFn = async (type: any) => {
      let params: any = {
        // type: type == "13" ? "1" : "2", // 1 法律解答 2 知识问答
        type: "2",
        keyword: state.keyword,
        page: state.page,
        limit: state.limit,
        role: state.role,
      };
      if (state.role == "1") {
        params.uid = getUid();
        params.tokenid = getToken();
      }
      return await getStudyList(params).then((res: any) => {
        if (res.code == "0") {
          return res;
        }
      });
    };

    const init = () => {
      if (state.type == "13") {
        modelListFn(state.type).then((res) => {
          state.list = res.data || [];
          state.total = Number(res.count);
        });
      } else if (state.type == "14") {
        studyListFn(state.type).then((res) => {
          state.list = res.data || [];
          state.total = Number(res.count);
        });
      }
    };

    const currentChange = (page: any) => {
      state.page = page;
      init();
    };

    init(); // 获取数据

    watch(
      () => state.type,
      (type, prevType) => {
        if (type != prevType) {
          init();
        }
      }
    );

    const ModelInfo = (id: any) => {
      getModelInfo({ id }).then((res: any) => {
        state.currentItem = res.data;
        state.loading = false;
      });
    };

    const StudyInfo = (id: any) => {
      getStudyInfo({ id }).then((res: any) => {
        state.currentItem = res.data;
      });
    };

    const openDetails = (item: any) => {
      if (state.type == "13") {
        state.isShow = true;
        state.isAskQuestionsShow = false;
        ModelInfo(item.id);
        state.loading = true;
        state.currentItem = { title: "", content: "" };
        // $router.push({
        //   query: ($route.query, { type: state.type, articleId: item.id }),
        // });
        const to = $router.resolve({
          path: $route.path,
          query: ($route.query, { type: state.type, articleId: item.id }),
        });
        window.open(to.href, "_blank");
      } else if (state.type == "14") {
        state.isShow = true;
        state.isAskQuestionsShow = false;
        StudyInfo(item.id);
        // $router.push({
        //   query: ($route.query, { type: state.type, articleId: item.id }),
        // });
        const to = $router.resolve({
          path: $route.path,
          query: ($route.query, { type: state.type, articleId: item.id }),
        });
        window.open(to.href, "_blank");
      }
    };

    watch(
      () => $route.query,
      (newQuery, oldQuery) => {
        if (newQuery?.type !== oldQuery?.type) {
          if ($route.query.type) {
            state.type = $route.query.type;
          }
          if ($route.query.articleId) {
            state.articleId = $route.query.articleId;
            state.isShow = true;
            ModelInfo(state.articleId);
            state.loading = true;
            state.isShow = true;
            state.isAskQuestionsShow = false;
            if (state.type == "13") {
              ModelInfo(state.articleId);
            } else if (state.type == "14") {
              StudyInfo(state.articleId);
            }
            // openDetails({ id: state.articleId });
          }
        }
      },
      { immediate: true }
    );

    // 显示提问页面
    const showAskQuestions = () => {
      state.isShow = false;
      state.isAskQuestionsShow = true;
    };

    // 我的提问
    const myQuestions = () => {
      state.role = "1";
      init();
    };

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef();
    // 内容 HTML
    const valueHtml = ref("");
    const toolbarConfig: Partial<IToolbarConfig> = {};
    const editorConfig = {
      placeholder: "请输入内容...",
      MENU_CONF: {
        // 配置默认字号
        // 配置上传图片
        uploadImage: {
          // 请求路径
          server: "/Home/Upload/upload",
          // 后端接收的文件名称
          fieldName: "file",
          maxFileSize: 1 * 1024 * 1024, // 1M
          // 上传的图片类型
          allowedFileTypes: ["image/*"],
          // 小于该值就插入 base64 格式（而不上传），默认为 0
          base64LimitSize: 1 * 1024, // 10MB
          customInsert(res: any, insertFn: any) {
            if (res.code != 0) {
              ElMessage.error("上传文件失败，" + res.message);
              return;
            }
            insertFn(
              res.data.file.path_url,
              res.data.file.name,
              res.data.file.path_url
            );
          },
          metaWithUrl: true,
          // 单个文件上传成功之后
          onSuccess(file: any, res: any) {
            if (res.code == 0) {
              ElMessage.success(`${file.name} 上传成功`);
              return;
            } else {
              ElMessage.warning(`${file.name} 上传出了点异常`);
              return;
            }
          },
          // 单个文件上传失败
          onFailed(file: any, res: any) {
            console.log(res);
            ElMessage.error(`${file.name} 上传失败`);
          },
          // 上传错误，或者触发 timeout 超时
          onError(file: any, err: any, res: any) {
            console.log(err, res);
            ElMessage.error(`${file.name} 上传出错`);
          },
        },
      },
    };

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    const handleCreated = (editor: any) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };

    const handleChange = (editor: any) => {
      if (!editor.isEmpty()) {
        valueHtml.value = editor.getHtml();
        state.form.content = editor.getHtml();
      } else {
        valueHtml.value = "";
        state.form.content = "";
      }
    };

    // 发布提问
    const save = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const params = {
            uid: getUid(),
            tokenid: getToken(),
            ...state.form,
            type: state.type == "13" ? "1" : "2", // 1 法律解答 2 知识问答
          };
          saveStudy(params).then((res: any) => {
            if (res.code == 0) {
              ElMessage({
                message: "发表提问成功！",
                type: "success",
              });
              state.isShow = false;
              state.isAskQuestionsShow = false;
            }
          });
        }
      });
    };

    return {
      ...toRefs(state),
      triangleIcon,
      store,
      rules,
      ruleFormRef,
      activeIndex,
      sidebarclick,
      init,
      currentChange,
      openDetails,
      showAskQuestions,
      myQuestions,
      editorRef,
      valueHtml,
      mode: "default", // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
      save,
    };
  },
  components: { Sidebar, Editor, Toolbar, Details },
});
